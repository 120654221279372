const chargeImage = (image) => {

    if(image?.dataset.src) {
        image.src = image.dataset.src
        image.removeAttribute('data-src')
    } else {
        return
    }
    
}
export default chargeImage