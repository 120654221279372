import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import event from 'dom-event'
import gsap from 'gsap'
import InView from 'inview'

class Information extends Default {
	constructor() {
		super('indexPage')
	}
	mounted() {

        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        document.body.classList.remove('snap')
        document.querySelector('html').classList.remove('snap')
        document.body.style.scrollSnapType = 'none'
        document.querySelector('html').style.scrollSnapType = 'none'
        window.scrollTo(0,0,0)

    }

    init() {
        

    }


    addEvents(type) {

    }

   

	beforeDestroy() {

		this.addEvents('off')

    }

	resize(w) {

		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            this.addEvents('off')
            this.mounted()
        }

	}
}

export default Information